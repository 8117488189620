import gsap from 'gsap';
import debounce from "lodash/debounce";

const menuComponent = document.querySelector('menu-component');
const shadowRoot = menuComponent.shadowRoot

menuComponent.classList.remove('block');

// START DESKTOP
const menubar = shadowRoot.querySelector('.menu-bar');
const mainMenuItems = shadowRoot.querySelector('.menu-bar-items--main');
const menuButtons = shadowRoot.querySelectorAll('button.menu-bar-link');
const hiddenChildren = document.querySelectorAll('menu-component > .hidden');
const submenus = shadowRoot.querySelectorAll('.menu-bar-sub-menu');
const searchbarSlots = {
  default: shadowRoot.querySelector('slot[name="searchbar"]'),
  mobile: shadowRoot.querySelector('slot[name="searchbar-mobile"]')
};

// We want to hide the children of the menu component until the JS is loaded
hiddenChildren.forEach((el) => {
  el.classList.remove('hidden');
});

const toggleCollapseDebounced = debounce(toggleCollapse, 50);

for (const button of menuButtons) {
  button.addEventListener('click', function (event) {
    const isOpen = button.getAttribute('aria-expanded') === 'true';
    selectMenuItem(button, !isOpen);
  });
  if (window.innerWidth < 1024) continue;
  button.addEventListener('focus', function (event) {
    const isClosed = button.getAttribute('aria-expanded') === 'false';
    if (isClosed) toggleCollapseDebounced(null, submenus);
  });
  button.parentNode.addEventListener('mouseenter', function (event) {
    selectMenuItem(button, true);
  });
  button.parentNode.addEventListener('mouseleave', function (event) {
    toggleCollapseDebounced(null, submenus);
  });
}

function selectMenuItem(el, enable) {
  const targetId = el.getAttribute('aria-controls');
  const target = shadowRoot.getElementById(targetId);
  toggleCollapseDebounced(target, submenus);
}

function handleOutsideSearchBarClick(event) {
  const isSearchBar = menuComponent.contains(event.target);
  if (!isSearchBar) enableSearchBar(false);
}

function listenForClickOutsideSearchBar(enable) {
  if (enable) {
    document.addEventListener('click', handleOutsideSearchBarClick);
  } else {
    document.removeEventListener('click', handleOutsideSearchBarClick);
  }
}

function enableSearchBar(enable) {
  const searchBar = window.innerWidth > 1024 ? searchbarSlots.default :searchbarSlots.mobile;
  menubar.classList.toggle("searchbar-active", enable);
  const menuTransform = { opacity: enable ? 0 : 1, duration: 0.3 };
  if (window.innerWidth > 1024) menuTransform.width = enable ? 0 : 'auto';
  gsap.to(mainMenuItems, menuTransform);
  gsap.to(searchBar, {
    opacity: enable ? 1 : 0,
    width: enable ? '100%' : 0,
    duration: 0.3,
    onComplete: () => {
      if (enable) document.dispatchEvent(new CustomEvent('searchbarActivated', {bubbles: true}));
      listenForClickOutsideSearchBar(enable);
    }
  });
}

shadowRoot.getElementById("search-button-open").addEventListener("click", function (event) {
  enableSearchBar(true);
});

shadowRoot.getElementById("search-button-close").addEventListener("click", function (event) {
  enableSearchBar(false);
});

if (!document.querySelector("[slot='searchbar']")) {
  shadowRoot.getElementById("search-button-open").style.display = "none";
}

// END DESKTOP
const mobileButton = shadowRoot.getElementById("mobile-nav-button");

function toggleCollapse(target, otherItems) {
  const shouldCloseMobile = target?.classList.contains('show') && window.innerWidth < 1024;
  let closeDuration = target ? 0 : 0.3;
  if (window.innerWidth < 1024) closeDuration = 0.3;

  // console.log(otherItems);
  let currentActiveItemHeight = 0;
  otherItems?.forEach((el) => {
    if (el.clientHeight > currentActiveItemHeight) currentActiveItemHeight = el.clientHeight;
    if (window.innerWidth > 1024 && (el === target || !el.classList.contains('show'))) return;
    if (!shouldCloseMobile && (el === target || !el.classList.contains('show'))) return;
    const controlId = el.getAttribute('aria-labelledby');
    const control = shadowRoot.getElementById(controlId);
    control.setAttribute('aria-expanded', 'false');
    gsap.to(el, { height: 0, duration: closeDuration, onComplete: () => {
      el.classList.remove('show');
      el.removeAttribute('style');
    }});
  });

  if (target && !shouldCloseMobile) {
    const controlId = target.getAttribute('aria-labelledby');
    const control = shadowRoot.getElementById(controlId);
    control?.setAttribute('aria-expanded', 'true');
    target.classList.add('show');
    gsap.fromTo(target, {height: currentActiveItemHeight}, {height: 'auto', duration: 0.3});

    if (currentActiveItemHeight) return;

    const subMenuItems = target.querySelectorAll('.sub-menu-item, .card');
    if (subMenuItems.length) {
      const hasCard = target.querySelector('.card');
      let columnCount = 1;
      if (window.innerWidth > 1024) columnCount = hasCard ? 2 : 3;

      const grid = [Math.ceil(subMenuItems.length / columnCount), columnCount];
      gsap.fromTo(subMenuItems,
        { y: -5, opacity: 0 },
        { y: 0, opacity: 1, duration: 0.3, delay: 0.2, stagger: { amount: 0.16, grid: grid, axis: null, from: 0 } }
      );
    }
  }
}

function toggleMobileMenu() {
  const target = mobileButton.getAttribute('aria-controls');
  const menu = shadowRoot.getElementById(target);
  document.body.classList.toggle('overflow-hidden');

  const isVisible = menu.classList.contains('show');
  mobileButton.setAttribute('aria-expanded', !isVisible ? 'true' : 'false');
  if (!isVisible) menu.classList.add('show', 'open');
  const height = window.innerHeight - menu.getBoundingClientRect().y;
  gsap.to(menu,  {
    height: isVisible ? 0 : height,
    duration: 0.3,
    onComplete: () => {
      if (isVisible) menu.classList.remove('show', 'open');
    }
  });
}

mobileButton.addEventListener("click", function (event) {
  toggleMobileMenu();
});

shadowRoot.addEventListener('keyup', function (event) {
  if (event.defaultPrevented) return; // Should do nothing if the default action has been cancelled
  if (!['Escape', 'Esc'].includes(event.key)) return; // Ignore if not Escape or k key

  const activeMenuItem = shadowRoot.querySelector('button.menu-bar-link[aria-expanded="true"]');
  if (activeMenuItem) return toggleCollapse(null, submenus);

  const openMobileMenu = mobileButton.getAttribute('aria-expanded') === 'true';
  if (openMobileMenu) return toggleMobileMenu();
});

window.addEventListener('keydown', function (event) {
  if (event.defaultPrevented) return; // Should do nothing if the default action has been cancelled
  if (!['Escape', 'Esc', 'k'].includes(event.key)) return; // Ignore if not Escape or k key
  const isOpen = menubar.classList.contains("searchbar-active");

  if (event.key === 'k' && event.metaKey) {
    enableSearchBar(!isOpen);
  } else if (isOpen && ['Escape', 'Esc'].includes(event.key)) {
    enableSearchBar(false);
  }
});
